import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import lozad from "lozad";
import ReactPlayer from "react-player/lazy";

import "./Gallery.css";
import "./Gallery_Custom.css";
import "./Gallery_mobile.css";
import "./Gallery_Custom_Mobile.css";

function Gallery() {
  const ref = useRef(null);
  const [imagedata, setImageData] = useState([]);
  const [imagearray, setImageArray] = useState([]);

  let [screenWidth, setScreenWidth] = useState(1024);

  const handleResize = () => {
    const { innerWidth: width, innerHeight: height } = window;
    console.log(width, height);
    setScreenWidth(() => width);
  };

  const images = () => {
    let size = 62;
    for (let i = 0; i < size; i += 4) {
      let temp = [];
      for (let j = i; j < i + 4; j++) {
        if (j < size) temp.push("/images/" + `Richmond${j}` + ".jpg");
      }
      setImageData((prev) => [...prev, temp]);
      // console.log(imagedata);
    }
  };
  // get the window size and check the width, if its smaller than 768 then
  //know its mobile and you do not want to change the scrolling direction

  const onWheel = (e) => {
    e.preventDefault();

    try {
      const container = scrollRef.current;
      const containerScrollPosition = scrollRef.current.scrollLeft;
      container.scrollTo({
        top: 0,
        left: containerScrollPosition + e.deltaY + e.deltaX,
      });
    } catch (err) {
      //console.log(err)
    }
  };
  const onWheel2 = (e, val) => {
    console.log("clicked");
    e.preventDefault();
    const container = scrollRef.current;
    const containerScrollPosition = scrollRef.current.scrollLeft;
    val === "Right"
      ? container.scrollTo({
          top: 0,
          left: containerScrollPosition + 1000,
          behavior: "smooth",
        })
      : container.scrollTo({
          top: 0,
          left: containerScrollPosition - 1000,
          behavior: "smooth",
        });
  };

  const scrollRef = useRef(null);
  useEffect(() => {
    images();
    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();
    let intViewportWidth = window.innerWidth;
    setScreenWidth(() => intViewportWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    //console.log(imagedata);
    console.log(screenWidth);
    var element = document.scrollingElement || document.documentElement;
    element.addEventListener("wheel", onWheel, { passive: false });

    return () => {
      element.removeEventListener("wheel", onWheel, { passive: false });
    };
  }, [screenWidth]);

  return (
    <>
      {/* {console.log(screenWidth)} */}
      {screenWidth > 1200 ? (
        <div className="Gallary_main container" ref={scrollRef} id="container">
          {/* {console.log(imagedata)} */}
          {/* {console.log(window.pageXOffset)} */}
          <div
            className="Gallery_ArrowLeft"
            onClick={(e) => {
              onWheel2(e, "left");
            }}
          >
            <img src="/images/Left-Button.png" alt=""></img>
          </div>
          <div
            className="Gallery_ArrowRight"
            onClick={(e) => {
              onWheel2(e, "Right");
            }}
          >
            <img src="/images/Right-Button.png" alt=""></img>
          </div>

          {imagedata.map((imagegroup, index) => {
            let key = index;
            {
              console.log(imagegroup, "the index is", index, index % 3);
            }
            return (
              <>
                <div className="Gallery_Container" key={`${index}${index % 3}`}>
                  <div
                    className="Gallery_Grid"
                    onContextMenu={(e) => e.preventDefault()}
                  >
                    {imagegroup[0] ? (
                      <>
                        {`${index}${0}` === "90" ? (
                          //  <video
                          //  className={`lozad Gallery_video${`${index}${4}`}`}
                          //  autoPlay
                          //  loop
                          //  muted
                          //  playsInline
                          //  >
                          //    <source src="/images/RichmondGreatSpace.mp4" type="video/mp4"></source>

                          //  </video>

                          <div
                            className={`lozad Gallery_video${`${index}${0}`}`}
                          >
                            <div className="video-container1">
                              <ReactPlayer
                                url="https://www.youtube.com/watch?v=YS8Uuj7cicI"
                                loop={1}
                                playing={true}
                                muted={true}
                                controls={false}
                                playsinline={true}
                                height="calc(16/9 * 50vw)"
                                width="100vw"
                                onPause={(e) => {
                                  window.location.reload(false);
                                }}
                                config={{
                                  youtube: {
                                    playerVars: {
                                      
                                      vq: "hd1080",
                                      autoplay: 1,
                                      mute: 1,
                                      playsinline: 1,
                                    },
                                  },
                                }}
                              />
                              {/* <iframe src="https://www.youtube.com/embed/F09W9JoKALs?vq=hd1080&amp;controls=0&amp;showinfo=0&amp;autoplay=1&amp;loop=1&amp;playsinline=1&amp;modestbranding=1&amp;mute=1&amp;playlist=F09W9JoKALs" 
                          
                          frameborder="0" allowfullscreen allow="autoplay"></iframe> */}
                            </div>
                          </div>
                        ) : (
                          <>
                            {`${index}${0}` === "140" ? (
                          //  <video
                          //  className={`lozad Gallery_video${`${index}${4}`}`}
                          //  autoPlay
                          //  loop
                          //  muted
                          //  playsInline
                          //  >
                          //    <source src="/images/RichmondGreatSpace.mp4" type="video/mp4"></source>

                          //  </video>

                          <div
                            className={`lozad Gallery_video${`${index}${0}`}`}
                          >
                            <div className="video-container1">
                              <ReactPlayer
                                url="https://www.youtube.com/watch?v=aHvCVveSxdQ"
                                loop={1}
                                playing={true}
                                muted={true}
                                controls={false}
                                playsinline={true}
                                height="calc(16/9 * 50vw)"
                                width="100vw"
                                onPause={(e) => {
                                  window.location.reload(false);
                                }}
                                config={{
                                  youtube: {
                                    playerVars: {
                                      
                                      vq: "hd1080",
                                      autoplay: 1,
                                      mute: 1,
                                      playsinline: 1,
                                    },
                                  },
                                }}
                              />
                              {/* <iframe src="https://www.youtube.com/embed/F09W9JoKALs?vq=hd1080&amp;controls=0&amp;showinfo=0&amp;autoplay=1&amp;loop=1&amp;playsinline=1&amp;modestbranding=1&amp;mute=1&amp;playlist=F09W9JoKALs" 
                          
                          frameborder="0" allowfullscreen allow="autoplay"></iframe> */}
                            </div>
                          </div>
                        ) : (



                          <div className={`Gallery_image${`${index}${0}`}`}>
                            <img src={imagegroup[0]} alt=""></img>
                          </div>
                        )}
                        </>
                        )}
                      </>
                    ) : null}

                    {imagegroup[1] ? (
                      <>
                        {index % 3 === 1 ? (
                          <div
                            className={`Gallery_image${`${index}${1}`}_text`}
                          >
                            {`${index}${1}` === "11" ? (
                              <h2 className={`Gallery_tile${`${index}${1}`}`}>
                                “The approach to scale and detail in the great
                                room expresses an almost stately elegance
                                reminiscent of the grand hotels.”
                              </h2>
                            ) : (
                              <>
                                {`${index}${1}` !== "131" ? (
                                  <>
                                  {`${index}${1}` === "71" ? (
                                  <h2
                                    className={`Gallery_tile${`${index}${1}`}`}
                                  >
                                    “The home <br />
                                    exudes luxury at
                                    <br />
                                    every turn, <br />
                                    enriching the
                                    <br />
                                    homeowners
                                    <br />
                                    lives beyond the <br />
                                    confines of the <br />
                                    space.”
                                  </h2>
                                  ):null}
                                  </>
                                ) : null}
                                 {`${index}${1}` === "101" ? (
                                  <h2
                                    className={`Gallery_tile${`${index}${1}`}`}
                                  >
                                    
                                    Dining Room is the heart of the home vibe with the 8’ Long 
                                    Crystal chandelier, 5’ extraordinary Fireplace 
                                    cladded with Portoro Marble are speaking luxury.
                                    Room is open to all spaces but still a destiny.<br />
                                  </h2>
                                ) : null}
                              </>
                            )}

                            {/* {11,71} */}
                          </div>
                        ) : (
                          <div className={`Gallery_image${`${index}${1}`}`}>
                            {`${index}${1}` === "31" ? (
                              <img src="/images/Richmond15.jpg" alt=""></img>
                            ) : (
                              <img src={imagegroup[1]} alt=""></img>
                            )}
                          </div>
                        )}
                      </>
                    ) : null}

                    {imagegroup[2] ? (
                      <>
                        {index % 3 === 2 ? (
                          <div
                            className={`Gallery_image${`${index}${2}`}_text`}
                          >
                            {`${index}${2}` === "22" ? (
                              <h2 className={`Gallery_tile${`${index}${2}`}`}>
                                “The dining room <br />
                                makes a <br />
                                sophisticated and <br />
                                tailored statement <br />
                                informed by
                                <br />
                                echoes of Art
                                <br />
                                Deco.”
                              </h2>
                            ) : (
                              <>
                                
                                {`${index}${2}` !== "142" ? (
                                  <>
                                  {`${index}${2}` === "52" ? (
                                    <h2
                                    className={`Gallery_tile${`${index}${2}`}`}
                                  >
                                    “The living room is anchored by the warmth
                                    of <br />
                                    the matte black nero marquina fireplace{" "}
                                    <br />
                                    with delicate bronze inlay.”
                                  </h2>
                                  ):null}
                                  </>
                                  
                                ) : null}
                                {`${index}${2}` === "82" ? (
                                  <h2
                                    className={`Gallery_tile${`${index}${2}`}`}
                                  >
                                    
                                    Family room with the double height feels bright,
                                    Sunny and speaks luxury<br />
                                  </h2>
                                ) : null}
                                
                                {`${index}${2}` === "112" ? (
                                  <h2
                                    className={`Gallery_tile${`${index}${2}`}`}
                                  >
                                    
                                    Lobby is the most important sense of arrival to 
                                    welcome you home, open concept to feel relaxed and 
                                    connected to your loved ones. Featured in Calacatta 
                                    marble floor and Bronze mirrors.<br />
                                  </h2>
                                ) : null}
                              </>
                            )}

                            {/* {22,52} */}
                          </div>
                        ) : (
                          <div className={`Gallery_image${`${index}${2}`}`}>
                            {index % 3 === 1 ? (
                              <img src={imagegroup[1]} alt=""></img>
                            ) : (
                              <img src={imagegroup[2]} alt=""></img>
                            )}
                          </div>
                        )}
                      </>
                    ) : null}

                    {imagegroup[3] ? (
                      <>
                        {`${index}${3}` === "113" ? (
                          //  <video
                          //  className={`lozad Gallery_video${`${index}${4}`}`}
                          //  autoPlay
                          //  loop
                          //  muted
                          //  playsInline
                          //  >
                          //    <source src="/images/RichmondGreatSpace.mp4" type="video/mp4"></source>

                          //  </video>

                          <div
                            className={`lozad Gallery_video${`${index}${3}`}`}
                          >
                            <div className="video-container1">
                              <ReactPlayer
                                url="https://www.youtube.com/watch?v=26uX7-fLpJ0"
                                loop={1}
                                playing={true}
                                muted={true}
                                controls={false}
                                playsinline={true}
                                height="calc(16/9 * 50vw)"
                                width="100vw"
                                onPause={(e) => {
                                  window.location.reload(false);
                                }}
                                config={{
                                  youtube: {
                                    playerVars: {
                                      
                                      vq: "hd1080",
                                      autoplay: 1,
                                      mute: 1,
                                      playsinline: 1,
                                    },
                                  },
                                }}
                              />
                              {/* <iframe src="https://www.youtube.com/embed/F09W9JoKALs?vq=hd1080&amp;controls=0&amp;showinfo=0&amp;autoplay=1&amp;loop=1&amp;playsinline=1&amp;modestbranding=1&amp;mute=1&amp;playlist=F09W9JoKALs" 
                          
                          frameborder="0" allowfullscreen allow="autoplay"></iframe> */}
                            </div>
                          </div>
                        ) : (
                          <>
                            {index % 3 === 0 ? (
                              <div
                                className={`Gallery_image${`${index}${3}`}_text`}
                              >
                                {`${index}${3}` === "03" ? (
                                  <h2
                                    className={`Gallery_tile${`${index}${3}`}`}
                                  >
                                    "The great room is a story of <br />
                                    sumptuous warmth within a <br />
                                    soaring space dripping with <br />
                                    crystal chandeliers."
                                  </h2>
                                ) : (
                                  <>
                                    {`${index}${3}` === "63" ? (
                                      <img src={imagegroup[3]} alt=""></img>
                                    ) : (
                                      <>
                                        {`${index}${3}` !== "123" ? (
                                          <>
                                            {`${index}${3}` === "33" ? (
                                          <h2
                                            className={`Gallery_tile${`${index}${3}`}`}
                                          >
                                            “The kitchen is the heart <br />
                                            of the home. The matter is a <br />
                                            stunning kitching, in carrara,
                                            <br />
                                            massacar, and white <br />
                                            lacquer.”
                                          </h2>
                                            ):null}
                                          {`${index}${3}` === "93" ? (
                                            <h2
                                              className={`Gallery_tile${`${index}${3}`}`}
                                            >
                                              
                                              Kitchen is the one of the most important pieces, 
                                              should be Timeless, Functional and Speaks luxury.
                                              Macassar and High-gloss paint are dazzling with the 
                                              Calacatta Marble and Crystal chandeliers.<br />
                                            </h2>
                                          ) : null}
                                          </>
                                        ) : null}
                                      </>
                                    )}
                                    
                                  </>
                                )}

                                {/* {03,33} */}
                              </div>
                            ) : (
                              <div className={`Gallery_image${`${index}${3}`}`}>
                                <img src={imagegroup[2]} alt=""></img>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    ) : null}

                    {imagegroup[3] ? (
                      <>
                        {`${index}${4}` === "14" ? (
                          //  <video
                          //  className={`lozad Gallery_video${`${index}${4}`}`}
                          //  autoPlay
                          //  loop
                          //  muted
                          //  playsInline
                          //  >
                          //    <source src="/images/RichmondGreatSpace.mp4" type="video/mp4"></source>

                          //  </video>

                          <div
                            className={`lozad Gallery_video${`${index}${4}`}`}
                          >
                            <div className="video-container">
                              <ReactPlayer
                                url="https://www.youtube.com/watch?v=F09W9JoKALs"
                                loop={1}
                                playing={true}
                                muted={true}
                                controls={false}
                                playsinline={true}
                                height="95vh"
                                width="calc(16/9 * 95vh)"
                                onPause={(e) => {
                                  window.location.reload(false);
                                }}
                                config={{
                                  youtube: {
                                    playerVars: {
                                      playlist: "F09W9JoKALs",
                                      vq: "hd1080",
                                      autoplay: 1,
                                      mute: 1,
                                      playsinline: 1,
                                    },
                                  },
                                }}
                              />
                              {/* <iframe src="https://www.youtube.com/embed/F09W9JoKALs?vq=hd1080&amp;controls=0&amp;showinfo=0&amp;autoplay=1&amp;loop=1&amp;playsinline=1&amp;modestbranding=1&amp;mute=1&amp;playlist=F09W9JoKALs" 
                          
                          frameborder="0" allowfullscreen allow="autoplay"></iframe> */}
                            </div>
                          </div>
                        ) : (
                          <>
                            {`${index}${4}` === "44" ? (
                              // <video
                              //   className={`lozad Gallery_video${`${index}${4}`}`}
                              //   autoPlay
                              //   loop
                              //   muted
                              //   playsInline
                              // >
                              //   <source
                              //     src="/images/RichmondFamilyRoom.mp4"
                              //     type="video/mp4"
                              //   ></source>
                              // </video>
                              <div
                                className={`lozad Gallery_video${`${index}${4}`}`}
                              >
                                <div className="video-container">
                                  <ReactPlayer
                                    url="https://www.youtube.com/watch?v=BLPENZ6NX7E"
                                    loop={1}
                                    playing={true}
                                    muted={true}
                                    controls={false}
                                    playsinline={true}
                                    height="95vh"
                                    width="calc(16/9 * 95vh)"
                                    onPause={(e) => {
                                      window.location.reload(false);
                                    }}
                                    config={{
                                      youtube: {
                                        playerVars: {
                                          playlist: "BLPENZ6NX7E",
                                          vq: "hd1080",
                                          autoplay: 1,
                                          mute: 1,
                                          playsinline: 1,
                                        },
                                      },
                                    }}
                                  />
                                  {/* <iframe src="https://www.youtube.com/embed/BLPENZ6NX7E?vq=hd1080&amp;controls=0&amp;showinfo=0&amp;autoplay=1&amp;loop=1&amp;playsinline=1&amp;modestbranding=1&amp;mute=1&amp;playlist=BLPENZ6NX7E" 
                           
                              frameborder="0" allowfullscreen allow="autoplay"></iframe> */}
                                </div>
                              </div>
                            ) : (
                              <>
                                {`${index}${4}` === "64" ? (
                                  // <video
                                  //   className={`lozad Gallery_video${`${index}${4}`}`}
                                  //   autoPlay
                                  //   loop
                                  //   muted
                                  //   playsInline
                                  // >
                                  //   <source
                                  //     src="/images/RichmondMasterSuite.mp4"
                                  //     type="video/mp4"
                                  //   ></source>
                                  // </video>
                                  <div
                                    className={`lozad Gallery_video${`${index}${4}`}`}
                                  >
                                    <div className="video-container">
                                      <ReactPlayer
                                        url="https://www.youtube.com/watch?v=XvC0UA4ekcY"
                                        loop={1}
                                        playing={true}
                                        muted={true}
                                        controls={false}
                                        playsinline={true}
                                        height="95vh"
                                        width="calc(16/9 * 95vh)"
                                        onPause={(e) => {
                                          window.location.reload(false);
                                        }}
                                        config={{
                                          youtube: {
                                            playerVars: {
                                              playlist: "XvC0UA4ekcY",
                                              vq: "hd1080",
                                              autoplay: 1,
                                              mute: 1,
                                              playsinline: 1,
                                            },
                                          },
                                        }}
                                      />
                                      {/* <iframe src="https://www.youtube.com/embed/XvC0UA4ekcY?vq=hd1080&amp;controls=0&amp;showinfo=0&amp;autoplay=1&amp;loop=1&amp;playsinline=1&amp;modestbranding=1&amp;mute=1&amp;playlist=XvC0UA4ekcY" 
                           
                           frameborder="0" allowfullscreen allow="autoplay"></iframe> */}
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    {`${index}${4}` === "34" ? null : (
                                      <div
                                        className={`Gallery_image${`${index}${4}`}`}
                                      >
                                        <img src={imagegroup[3]} alt=""></img>
                                        {/* {14,44,64}*/}
                                      </div>
                                    )}
                                    {/**/}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    ) : null}
                  </div>
                </div>
                <div></div>
              </>
            );
          })}
        </div>
      ) : (
        //
        //
        //
        //
        //
        //
        //
        // This is Mobile View logic
        <div className="Gallary_main" onContextMenu={(e) => e.preventDefault()}>
          {/* {console.log(imagedata)} */}
          {/* {console.log(window.pageXOffset)} */}

          {imagedata.map((imagegroup, index) => {
            let key = index;
            {
              console.log(imagegroup, index, index % 3);
            }
            return (
              <>
                {imagegroup[0] ? (
                  <>
                    {`${index}${0}` === "90" ? (
                      //  <video
                      //  className={`lozad Gallery_video${`${index}${4}`}`}
                      //  autoPlay
                      //  loop
                      //  muted
                      //  playsInline
                      //  >
                      //    <source src="/images/RichmondGreatSpace.mp4" type="video/mp4"></source>

                      //  </video>

                      <div className={`lozad Gallery_video${`${index}${0}`}`}>
                        <div className="video-container1">
                          <ReactPlayer
                            url="https://www.youtube.com/watch?v=YS8Uuj7cicI"
                            loop={1}
                            playing={true}
                            muted={true}
                            controls={false}
                            playsinline={true}
                            height="calc(16/9 * 100vw)"
                            width="100vw"
                            onPause={(e) => {
                              window.location.reload(false);
                            }}
                            config={{
                              youtube: {
                                playerVars: {
                                
                                  vq: "hd1080",
                                  autoplay: 1,
                                  mute: 1,
                                  playsinline: 1,
                                },
                              },
                            }}
                          />
                          {/* <iframe src="https://www.youtube.com/embed/F09W9JoKALs?vq=hd1080&amp;controls=0&amp;showinfo=0&amp;autoplay=1&amp;loop=1&amp;playsinline=1&amp;modestbranding=1&amp;mute=1&amp;playlist=F09W9JoKALs" 
                    
                    frameborder="0" allowfullscreen allow="autoplay"></iframe> */}
                        </div>
                      </div>
                    ) : (
                      <>
                      {`${index}${0}` === "140" ? (
                          //  <video
                          //  className={`lozad Gallery_video${`${index}${4}`}`}
                          //  autoPlay
                          //  loop
                          //  muted
                          //  playsInline
                          //  >
                          //    <source src="/images/RichmondGreatSpace.mp4" type="video/mp4"></source>

                          //  </video>

                          <div
                            className={`lozad Gallery_video${`${index}${0}`}`}
                          >
                            <div className="video-container1">
                              <ReactPlayer
                                url="https://www.youtube.com/watch?v=aHvCVveSxdQ"
                                loop={1}
                                playing={true}
                                muted={true}
                                controls={false}
                                playsinline={true}
                                height="calc(16/9 * 100vw)"
                                width="100vw"
                                onPause={(e) => {
                                  window.location.reload(false);
                                }}
                                config={{
                                  youtube: {
                                    playerVars: {
                                      
                                      vq: "hd1080",
                                      autoplay: 1,
                                      mute: 1,
                                      playsinline: 1,
                                    },
                                  },
                                }}
                              />
                              {/* <iframe src="https://www.youtube.com/embed/F09W9JoKALs?vq=hd1080&amp;controls=0&amp;showinfo=0&amp;autoplay=1&amp;loop=1&amp;playsinline=1&amp;modestbranding=1&amp;mute=1&amp;playlist=F09W9JoKALs" 
                          
                          frameborder="0" allowfullscreen allow="autoplay"></iframe> */}
                            </div>
                          </div>
                        ) : (
                          <>
                          {`${index}${0}` === "80" ? (
                      <div className={`Gallery_image${`${index}${0}`}`}>
                        <img src="/images/Richmond62.jpg" alt=""></img>
                      </div>
                          ):(
                            <div className={`Gallery_image${`${index}${0}`}`}>
                        <img src={imagegroup[0]} alt=""></img>
                      </div>
                          )}
                      </>
                        )}
                      </>
                    )}
                  </>
                ) : null}

                {imagegroup[1] ? (
                  <>
                    {index % 3 === 1 ? (
                          <div
                            className={`Gallery_image${`${index}${1}`}_text`}
                          >
                            {`${index}${1}` === "11" ? (
                              <h2 className={`Gallery_tile${`${index}${1}`}`}>
                                “The approach to scale and detail in the great
                                room expresses an almost stately elegance
                                reminiscent of the grand hotels.”
                              </h2>
                            ) : (
                              <>
                                {`${index}${1}` !== "131" ? (
                                  <>
                                  {`${index}${1}` === "71" ? (
                                  <h2
                                    className={`Gallery_tile${`${index}${1}`}`}
                                  >
                                    “The home 
                                    exudes luxury at
                                    every turn,
                                    enriching the
                                    
                                    homeowners
                                    
                                    lives beyond the 
                                    confines of the 
                                    space.”
                                  </h2>
                                  ):null}
                                  </>
                                ) : null}
                                 {`${index}${1}` === "101" ? (
                                  <h2
                                    className={`Gallery_tile${`${index}${1}`}`}
                                  >
                                    
                                    Dining Room is the heart of the home vibe with the 8’ Long 
                                    Crystal chandelier, 5’ extraordinary Fireplace 
                                    cladded with Portoro Marble are speaking luxury.
                                    Room is open to all spaces but still a destiny.<br />
                                  </h2>
                                ) : null}
                              </>
                            )}

                            {/* {11,71} */}
                          </div>
                        ) : (
                          <div className={`Gallery_image${`${index}${1}`}`}>
                            {`${index}${1}` === "31" ? (
                              <img src="/images/Richmond15.jpg" alt=""></img>
                            ) : (
                              <img src={imagegroup[1]} alt=""></img>
                            )}
                          </div>
                        )}
                      </>
                    ) : null}

                {imagegroup[2] ? (
                  <>
                    {index % 3 === 2 ? (
                      <>
                      {`${index}${2}` !== "142" ? (
                        <div className={`Gallery_image${`${index}${2}`}_text`}>
                        {`${index}${2}` === "22" ? (
                          <h2 className={`Gallery_tile${`${index}${2}`}`}>
                            “The dining room makes a sophisticated and tailored
                            statement informed by echoes of Art Deco.”
                          </h2>
                        ) : (
                          <>
                          {`${index}${2}` === "52" ? (
                          
                          <h2 className={`Gallery_tile${`${index}${2}`}`}>
                            “The living room is anchored by the warmth of the
                            matte black nero marquina fireplace with delicate
                            bronze inlay.”
                          </h2>
                          ):null}
                          {`${index}${2}` === "82" ? (
                                  <h2
                                    className={`Gallery_tile${`${index}${2}`}`}
                                  >
                                    
                                    Family room with the double height feels 
                                    bright, Sunny and speaks luxury.<br />
                                  </h2>
                                ) : null}
                                
                                {`${index}${2}` === "112" ? (
                                  <h2
                                    className={`Gallery_tile${`${index}${2}`}`}
                                  >
                                    
                                    Lobby is the most important sense of arrival to 
                                    welcome you home, open concept to feel relaxed and 
                                    connected to your loved ones. Featured in Calacatta 
                                    marble floor and Bronze mirrors.<br />
                                  </h2>
                                ) : null}
                          </>
                          
                        )}

                        {/* {22,52} */}
                      </div>
                      ):(null)}


                      </>
                      
                    ) : (
                      <>
                        {`${index}${2}` !== "92" ? (
                          <div className={`Gallery_image${`${index}${2}`}`}>
                            {index % 3 === 1 ? (
                              <img src={imagegroup[1]} alt=""></img>
                            ) : (
                              <img src={imagegroup[2]} alt=""></img>
                            )}
                          </div>
                        ) : null}
                      </>
                    )}
                  </>
                ) : null}

                {imagegroup[3] ? (
                  <>
                    {`${index}${3}` === "113" ? (
                      // <video
                      //   className={`lozad Gallery_video${`${index}${4}`}`}
                      //   autoPlay
                      //   loop
                      //   muted
                      //   playsInline
                      // >
                      //   <source
                      //     src="/images/RichmondMasterSuite.mp4"
                      //     type="video/mp4"
                      //   ></source>
                      // </video>
                      <div className={`lozad Gallery_video${`${index}${3}`}`}>
                        <div className="video-container1">
                          <ReactPlayer
                            url="https://www.youtube.com/watch?v=26uX7-fLpJ0"
                            loop={1}
                            playing={true}
                            muted={true}
                            controls={false}
                            playsinline={true}
                            height="calc(16/9 * 100vw)"
                            width="100vw"
                            onPause={(e) => {
                              window.location.reload(false);
                            }}
                            config={{
                              youtube: {
                                playerVars: {
                                  
                                  vq: "hd1080",
                                  autoplay: 1,
                                  mute: 1,
                                  playsinline: 1,
                                },
                              },
                            }}
                          />
                          {/* <iframe src="https://www.youtube.com/embed/XvC0UA4ekcY?vq=hd1080&amp;controls=0&amp;showinfo=0&amp;autoplay=1&amp;loop=1&amp;playsinline=1&amp;modestbranding=1&amp;mute=1&amp;playlist=XvC0UA4ekcY" 
                           
                           frameborder="0" allowfullscreen allow="autoplay"></iframe> */}
                        </div>
                      </div>
                    ) : (
                      <>
                        {index % 3 === 0 ? (
                           <>
                           {`${index}${3}` !== "123" ? (
                             <div
                             className={`Gallery_image${`${index}${3}`}_text`}
                           >
                             {`${index}${3}` === "03" ? (
                               <h2 className={`Gallery_tile${`${index}${3}`}`}>
                                 "The great room is a story of sumptuous warmth
                                 within a soaring space dripping with crystal
                                 chandeliers."
                               </h2>
                             ) : (
                               <>
                                 {`${index}${3}` === "63" ? (
                                   <img src={imagegroup[3]} alt=""></img>
                                 ) : (
                                  <>
                                  {`${index}${3}` === "33" ? (
                                   <h2
                                     className={`Gallery_tile${`${index}${3}`}`}
                                   >
                                     “The kitchen is the heart of the home. The
                                     matter is a stunning kitching, in carrara,
                                     massacar, and white lacquer.”
                                   </h2>
                                  ):null}
                                  {`${index}${3}` === "93" ? (
                                            <h2
                                              className={`Gallery_tile${`${index}${3}`}`}
                                            >
                                              
                                              Kitchen is the one of the most important pieces, 
                                              should be Timeless, Functional and Speaks luxury.
                                              Macassar and High-gloss paint are dazzling with the 
                                              Calacatta Marble and Crystal chandeliers.<br />
                                            </h2>
                                          ) : null}
                                   </>
                                 )}
                               </>
                             )}
 
                             {/* {03,33} */}
                           </div>
                           ) : (
                            null
                           )}
                           </>
                          
                          
                          
                        ) : (
                          <div className={`Gallery_image${`${index}${3}`}`}>
                            <img src={imagegroup[2]} alt=""></img>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : null}
                {imagegroup[3] ? (
                  <>
                    {`${index}${4}` === "14" ? (
                      //  <video
                      //  className={`lozad Gallery_video${`${index}${4}`}`}
                      //  autoPlay
                      //  loop
                      //  muted
                      //  playsInline
                      //  >
                      //    <source src="/images/RichmondGreatSpace.mp4" type="video/mp4"></source>

                      //  </video>
                      <div className={`lozad Gallery_video${`${index}${4}`}`}>
                        <div className="video-container">
                          <ReactPlayer
                            url="https://www.youtube.com/watch?v=F09W9JoKALs"
                            loop={1}
                            playing={true}
                            muted={true}
                            controls={false}
                            playsinline={true}
                            height="calc(9/16 * 100vw)"
                            width="100vw"
                            onPause={(e) => {
                              window.location.reload(false);
                            }}
                            config={{
                              youtube: {
                                playerVars: {
                                  playlist: "F09W9JoKALs",
                                  autoplay: 1,
                                  mute: 1,
                                  playsinline: 1,
                                },
                              },
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        {`${index}${4}` === "44" ? (
                          //  <video
                          //  className={`lozad Gallery_video${`${index}${4}`}`}
                          //  autoPlay
                          //  loop
                          //  muted
                          //  playsInline
                          //  >
                          //    <source src="/images/RichmondFamilyRoom.mp4" type="video/mp4"></source>

                          //  </video>
                          <div
                            className={`lozad Gallery_video${`${index}${4}`}`}
                          >
                            <div className="video-container">
                              <ReactPlayer
                                url="https://www.youtube.com/watch?v=BLPENZ6NX7E"
                                loop={1}
                                playing={true}
                                muted={true}
                                controls={false}
                                playsinline={true}
                                height="calc(9/16 * 100vw)"
                                width="100vw"
                                onPause={(e) => {
                                  window.location.reload(false);
                                }}
                                config={{
                                  youtube: {
                                    playerVars: {
                                      playlist: "BLPENZ6NX7E",
                                      autoplay: 1,
                                      mute: 1,
                                      playsinline: 1,
                                    },
                                  },
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          <>
                            {`${index}${4}` === "64" ? (
                              // <video
                              // className={`lozad Gallery_video${`${index}${4}`}`}
                              // autoPlay
                              // loop
                              // muted
                              // playsInline
                              // >
                              //   <source src="/images/RichmondMasterSuite.mp4" type="video/mp4"></source>

                              // </video>
                              <div
                                className={`lozad Gallery_video${`${index}${4}`}`}
                              >
                                <div className="video-container">
                                  <ReactPlayer
                                    url="https://www.youtube.com/watch?v=XvC0UA4ekcY"
                                    loop={1}
                                    playing={true}
                                    muted={true}
                                    controls={false}
                                    playsinline={true}
                                    height="calc(9/16 * 100vw)"
                                    width="100vw"
                                    onPause={(e) => {
                                      window.location.reload(false);
                                    }}
                                    config={{
                                      youtube: {
                                        playerVars: {
                                          playlist: "XvC0UA4ekcY",
                                          autoplay: 1,
                                          mute: 1,
                                          playsinline: 1,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            ) : (
                              <>
                                {`${index}${4}` === "24" ||
                                `${index}${4}` === "34" ? null : (
                                  <div
                                    className={`Gallery_image${`${index}${4}`}`}
                                  >
                                    <img src={imagegroup[3]} alt=""></img>
                                    {/* {14,44,64}*/}
                                  </div>
                                )}
                                {/**/}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : null}
              </>
            );
          })}
        </div>
      )}
    </>
  );
}

export default Gallery;

/*<HorizontalScroll>
      <div className="Gallary_main ">
        
        {console.log(window.pageYOffset)}
        {imagedata.map((imagegroup, index) => {
          let key = index;
          return (
            <>
              <div className="Gallery_images1">
                <img src={imagegroup[0]} alt=""></img>
              </div>
              <div className="Gallery_right">
                <img
                  className="Gallery_images2"
                  src={imagegroup[1]}
                  alt=""
                ></img>
                <img
                  className="Gallery_images3"
                  src={imagegroup[2]}
                  alt=""
                ></img>
              </div>
              <div className="Gallery_images4">
                <img src={imagegroup[3]} alt=""></img>
              </div>
            </>
          );
        })}
      </div>
    </HorizontalScroll> 
    */
